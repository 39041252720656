<script setup lang="ts">
import modal from '/~/core/mdl'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'
import BaseMdl from '/~/components/mdl/mdl-popup.vue'

withDefaults(
  defineProps<{
    points?: number
  }>(),
  {
    points: 5,
  }
)

function close() {
  modal.hide('earned-points')
}
</script>

<template>
  <base-mdl
    width="xxs"
    border-radius="md"
    layout="v2"
    modal-class="bg-primary-lightest"
  >
    <template #header>
      <div>
        <base-button
          slot="menu"
          :size="32"
          icon="heroicons/solid/x-mark"
          data-test="modal-close"
          class="!absolute right-2 top-2 text-eonx-neutral-800"
          @click="close"
        />
      </div>
    </template>
    <div class="flex flex-col items-center pt-7">
      <base-icon svg="v2/custom/points-token" :size="82" class="text-primary" />

      <div class="mt-6 text-center text-lg font-bold text-eonx-neutral-800">
        You’ve earned points!
      </div>
      <div class="mt-1 text-3xl font-black text-eonx-neutral-800">
        +{{ points }} PTS
      </div>
    </div>
  </base-mdl>
</template>
